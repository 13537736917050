<template>
  <div class="row m-0 justify-content-center">
    <div class="col-11 col-sm-10 col-md-9 col-lg-8 col-xl-7 p-0 mt-5 mb-3 card_item">
      <form @submit.prevent="submitContact">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-0">
            <ContentWithBackgroundAndFooter>
              <div class="row m-0 justify-content-center">
                <div class="col-12 text-center p-0 my-4">
                  <div class="heading">
                    Get in touch...
                  </div>
                </div>
                <div class="col-md-6">
                  <TextField
                    type="text"
                    color="gold"
                    label="Name:"
                    placeholder="Name"
                    v-model="form.fullName"
                    id="name"
                    :watchFocus="true"
                    @checkFocus="checkName"
                    :errors="fullNameErrors.errors"
                    :hasErrors="fullNameErrors.hasErrors"
                    required="required"
                    class="mb-3"
                  />
                  <TextField
                    type="email"
                    color="gold"
                    label="Email Address:"
                    placeholder="Email Address"
                    v-model="form.email"
                    id="email"
                    :watchFocus="true"
                    @checkFocus="checkEmail"
                    :errors="emailErrors.errors"
                    :hasErrors="emailErrors.hasErrors"
                    required="required"
                    class="mb-3"
                  />
                  <PhoneFieldInt
                    type="tel"
                    label="Mobile/Tel:"
                    placeholder="Mobile/Tel"
                    :showError="cellNumberErrors.showError"
                    v-model:modelValue="form.cellNumber"
                    v-model:theError="isValidNumber"
                    id="mobile"
                    color="gold"
                    :watchFocus="true"
                    @checkFocus="checkCellNumber"
                    :errors="cellNumberErrors.errors"
                    :hasErrors="cellNumberErrors.hasErrors"
                    required="required"
                    class="mb-3"
                  />
                </div>
                <div class="col-md-6">
                  <SelectAll
                    v-model="form.enquirySubject"
                    id="reason"
                    :options="contactReasons.enquiryTypeDTOLists"
                    displayValue="value"
                    saveValue="key"
                    placeholder="Reason"
                    color="gold"
                    :errors="enquirySubjectErrors.errors"
                    :hasErrors="enquirySubjectErrors.hasErrors"
                    :isGrouped="true"
                    required="required"
                    label="Reason"
                    :watchFocus="true"
                    @checkFocus="checkSubject"
                    class="mb-3"
                  />
                  <TextArea
                    label="Message:"
                    placeholder="Message"
                    v-model="form.message"
                    id="message"
                    bg="green"
                    :watchFocus="true"
                    @checkFocus="checkMessage"
                    :errors="messageErrors.errors"
                    :hasErrors="messageErrors.hasErrors"
                    required="required"
                    class="mb-3"
                  />
                </div>
                <div class="col-12 mb-3 text-end">
                  <vue-recaptcha sitekey="6LekV4QaAAAAANDiHSSNgdgagUXENoFbyShas5XS"
                    size="normal"
                    theme="light"
                    @verify="recaptchaVerified"
                    @expire="recaptchaExpired"
                    @fail="recaptchaFailed"
                    ref="vueRecaptcha">
                  </vue-recaptcha>
                </div>
                <div class="col-12 mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="consent" id="consent" v-model="form.correspondence">
                    <label class="form-check-label" for="flexCheckDefault">
                      I am happy to receive updates from Al-Imdaad Foundation by email, phone and SMS, and may opt-out
                    </label>
                  </div>
                </div>
              </div>
            </ContentWithBackgroundAndFooter>
          </div>
          <div class="col-12 p-3 bg text-center">
            <Button color="gold" :btnText="formStatus" icon="arrow" :disabled="disabled" v-if="formStatus !== 'Submit Message'">
              <IconArrowForward color="black" />
            </Button>
            <Button color="gold" :btnText="formStatus" icon="arrow" type="submit" v-else>
              <IconArrowForward color="black" />
            </Button>
          </div>
        </div>
      </form>
    </div>
    <transition name="slideInUp">
      <Popup v-if="formStatus === 'Sent'" @close="formStatus = 'Submit Message'">
        <div class="row mt-4 justify-content-center text-center">
          <div class="col-7 col-md-6 text-center">
            <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
            <TickAnimation v-else />
          </div>
          <div class="col-12 mb-3 text-center medium font23 green-text">
            Jazakallah Khair
          </div>
          <div class="col-10 mb-3 bigger_font text-center medium green-text">
            We've recieved your message and will get back to your shortly.
          </div>
          <div class="col-10 mb-3 text-center font15">
            Note: Please check your spam/junk folder to avoid missing our response.
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import vueRecaptcha from 'vue3-recaptcha2'

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    TextArea: defineAsyncComponent(() => import('@/components/TextArea.vue')),
    PhoneFieldInt: defineAsyncComponent(() => import('@/components/PhoneFieldInt.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    TickAnimation: defineAsyncComponent(() => import('@/components/animations/TickAnimation.vue')),
    ContentWithBackgroundAndFooter: defineAsyncComponent(() => import('@/components/styling/ContentWithBackgroundAndFooter.vue')),
    vueRecaptcha
  },
  name: 'Contact',
  data () {
    return {
      form: {
        fullName: '',
        email: '',
        cellNumber: '',
        message: '',
        enquirySubject: '',
        correspondence: false
      },
      formStatus: 'Submit Message',
      url: process.env.VUE_APP_URL_BASE,
      disabled: true,
      isValidNumber: true,
      selectedBranch: null,
      captchaReady: false,
      fullNameErrors: {
        errors: [],
        hasErrors: 0
      },
      emailErrors: {
        errors: [],
        hasErrors: 0
      },
      cellNumberErrors: {
        errors: [],
        hasErrors: 0,
        showError: false
      },
      messageErrors: {
        errors: [],
        hasErrors: 0
      },
      enquirySubjectErrors: {
        errors: [],
        hasErrors: 0
      }
    }
  },
  async mounted () {
    await this.getContactReason()
  },
  methods: {
    ...mapActions([
      'sendForm',
      'getContactReason'
    ]),
    async checkStatus () {
      await this.checkName()
      await this.checkEmail()
      await this.checkMessage()
      await this.checkCellNumber()
      await this.checkSubject()
      if (!this.form.fullName || !this.form.email || !this.form.cellNumber || !this.form.message || !this.form.enquirySubject || !this.isValid || !this.captchaReady) {
        this.disabled = true
        return 'failed'
      } else {
        this.disabled = false
        return 'passed'
      }
    },
    checkName () {
      console.log('CHECKNAME')
      if (!this.form.fullName) {
        this.fullNameErrors.errors = ['Required']
        this.fullNameErrors.hasErrors = 1
        this.formStatus = 'Complete Required Fields'
        setTimeout(() => {
          this.formStatus = 'Submit Message'
        }, 4000)
      } else {
        this.fullNameErrors.errors = []
        this.fullNameErrors.hasErrors = 0
      }
    },
    checkEmail () {
      console.log('checkEmail')
      if (!this.form.email) {
        this.emailErrors.errors = ['Required']
        this.emailErrors.hasErrors = 1
        this.formStatus = 'Complete Required Fields'
        setTimeout(() => {
          this.formStatus = 'Submit Message'
        }, 4000)
      } else {
        this.emailErrors.errors = []
        this.emailErrors.hasErrors = 0
      }
    },
    checkCellNumber () {
      console.log('checkCellNumber')
      this.cellNumberErrors.showError = true
      // if (this.isValid) {
      //   this.cellNumberErrors.errors = []
      //   this.cellNumberErrors.hasErrors = 0
      // } else {
      //   this.cellNumberErrors.errors = ['Invalid Phone Number']
      //   this.cellNumberErrors.hasErrors = 1
      // }
    },
    checkMessage () {
      console.log('checkMessage')
      if (!this.form.message) {
        this.messageErrors.errors = ['Required']
        this.messageErrors.hasErrors = 1
        this.formStatus = 'Complete Required Fields'
        setTimeout(() => {
          this.formStatus = 'Submit Message'
        }, 4000)
      } else {
        this.messageErrors.errors = []
        this.messageErrors.hasErrors = 0
      }
    },
    checkSubject () {
      console.log('checkMessage')
      if (!this.form.enquirySubject) {
        this.enquirySubjectErrors.errors = ['Required']
        this.enquirySubjectErrors.hasErrors = 1
        this.formStatus = 'Complete Required Fields'
        setTimeout(() => {
          this.formStatus = 'Submit Message'
        }, 4000)
      } else {
        this.enquirySubjectErrors.errors = []
        this.enquirySubjectErrors.hasErrors = 0
      }
    },
    recaptchaVerified (response) {
      this.captchaReady = true
    },
    recaptchaExpired () {
      this.$refs.vueRecaptcha.reset()
      this.captchaReady = false
      this.formStatus = 'Submit Message'
    },
    recaptchaFailed () {
      this.captchaReady = false
      this.formStatus = 'Submit Message'
    },
    async submitContact () {
      const ret = await this.checkStatus()
      if (ret === 'passed') {
        this.formStatus = 'Submitting'
        this.actuallySendForm()
      }
    },
    actuallySendForm () {
      this.sendForm(this.form).then((ret) => {
        if (ret) {
          this.clearForm()
          this.formStatus = 'Sent'
        } else {
          this.formStatus = 'Error, try again'
          setTimeout(() => {
            this.formStatus = 'Submit Message'
          }, 4000)
        }
      })
    },
    clearForm () {
      this.form.fullName = ''
      this.form.email = ''
      this.form.cellNumber = ''
      this.form.message = ''
      this.form.enquirySubject = ''
      this.form.correspondence = false
    }
  },
  computed: {
    ...mapGetters([
      'contactReasons', 'isApp'
    ]),
    nameError () {
      if (this.form.fullName) {
        return false
      } else {
        return true
      }
    },
    emailError () {
      if (this.form.email) {
        return false
      } else {
        return true
      }
    },
    messageError () {
      if (this.form.message) {
        return false
      } else {
        return true
      }
    },
    subjectError () {
      if (this.form.enquirySubject) {
        return false
      } else {
        return true
      }
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    isCorrectLength () {
      if (this.form.cellNumber) {
        if (this.form.cellNumber && (this.form.cellNumber).length !== 12) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    isValid () {
      if (!this.form.cellNumber || !this.isValidNumber || !this.isCorrectLength) {
        return false
      } else {
        return true
      }
    },
    telError () {
      let error = null
      if (!this.form.cellNumber) {
        error = []
        error.push({
          message: 'Enter a number'
        })
      } else if (!this.isValidNumber) {
        error = []
        error.push({
          message: 'Enter a valid number'
        })
      } else {
        error = []
      }
      return error
    }
  }
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
  color: #333;
}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
}
.bg {
  background-color: var(--green-color-dark);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 29px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 8px;
}
.divider {
  background-color: #ddd;
  width: 100%;
  height: 1px;
}
.big_text {
  font-size: 1.8rem;
}
.small_font {
  font-size: 0.8rem;
}
.bg_image {
  /* background-image: url(./assets/images/contact_bg.jpg); */
  color: #fff;
  font-weight: 500;
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  line-height: 1.8;
  background-repeat: no-repeat;
  position: relative;
  background-position: top;
  background-size: 100% 750px;
}
.bg_image.uk {
  /* background-image: url(/assets/images/contact_bg.jpg); */
  color: #fff;
  font-weight: 500;
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  line-height: 1.8;
  background-repeat: no-repeat;
  position: relative;
  background-position: top;
  background-size: cover;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 750px;
  padding: 0;
  background-color: transparent;
  background-image: linear-gradient(180deg, #0A1825 0%, #00000033 100%);
  opacity: 0.89;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.overlay.uk {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  background-image: linear-gradient(180deg, #0A1825 0%, #00000033 100%);
  opacity: 0.89;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.above_overlay {
  z-index: 2;
}
.gold_heading {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 700;
  letter-spacing: 2.4px;
  color: var(--gold-color);
  font-size: 1rem;
  text-transform: uppercase;
}
.contact_heading {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 700;
  font-size: 2.2rem;
  color: #fff;
  line-height: 1;
}
.contact_heading_underline {
  border-bottom: 2px solid var(--gold-color);
}
.round_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.smallheading {
  color: var( --green-color );
  font-family: "quicksand_bold", Sans-serif;
  font-size: 1.7rem;
  font-weight: bold;
}
.title_card {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 29px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 8px;
}
.infoText {
  color: #54595F;
  font-family: "quicksand", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}
@media (min-width: 992px) {

  .divider {
    background-color: #ddd;
    width: 1px;
    height: 100%;
  }
  .contact_heading {
    font-size: 3.3rem;
  }
}
</style>
